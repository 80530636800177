import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import "./PartnersBar.css"

const PartnersBar = () => {
  const query = useStaticQuery(graphql`
    query {
      allFile (filter: {sourceInstanceName: {eq: "content"} name: {eq: "about"}}) {
        edges {
          node {
            childMarkdownRemark {
              frontmatter {
                partnersBarHeading
                partner1 {
                  image {
                    childImageSharp {
                      fluid(quality: 90) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                  link
                }
                partner2 {
                  image {
                    childImageSharp {
                      fluid(quality: 90) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                  link
                }
                partner3 {
                  image {
                    childImageSharp {
                      fluid(quality: 90) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                  link
                }
                partner4 {
                  image {
                    childImageSharp {
                      fluid(quality: 90) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                  link
                }
              }
            }
          }
        }
      }
    }
  `)
  const data = query.allFile.edges[0].node.childMarkdownRemark.frontmatter

  const Partners = ({ PartnersArr }) => (
    <div className="Array">
      {PartnersArr.map(P => (
        <div className="Partner">
          <a href={P.link} target="_blank" rel="noreferrer">
            <Img fluid={P.image} />
          </a>
        </div>
      ))}
    </div>
  )

  return (
    <div className="PartnersBarContainer">
      <h3>{data.partnersBarHeading}</h3>
      <Partners PartnersArr={[
        {image: data.partner1.image.childImageSharp.fluid, link: data.partner1.link},
        {image: data.partner2.image.childImageSharp.fluid, link: data.partner2.link},
        {image: data.partner3.image.childImageSharp.fluid, link: data.partner3.link},
        {image: data.partner4.image.childImageSharp.fluid, link: data.partner4.link}
      ]} />
    </div>
  )
}

export default PartnersBar