import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import Seo from "../components/Seo"
import HeroBox from "../components/HeroBox"
import LayoutInner from "../components/LayoutInner"
import KarenProfile from "../components/KarenProfile"
import FullWidthBar from "../components/FullWidthBar"
import PartnersBar from "../components/PartnersBar"
import CarouselAboutUs from "../components/CarouselAboutUs"
import ContactBar from "../components/ContactBar"

export const query = graphql`
  query {
    allFile (filter: {sourceInstanceName: {eq: "content"} name: {eq: "about"}}) {
      edges {
        node {
          childMarkdownRemark {
            frontmatter {
              headerTitle
              headerSubtitle
              karenProfile {
                heading
                text
                image {
                  childImageSharp {
                    fluid(quality: 90) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

const AboutPage = ( props ) => {
  const data = props.data.allFile.edges[0].node.childMarkdownRemark.frontmatter

  return (
    <>
      <Layout>
        <Seo title="About Us" />
        <HeroBox heading={data.headerTitle} text={data.headerSubtitle} />
        <LayoutInner>
          <KarenProfile heading={data.karenProfile.heading} text={data.karenProfile.text} image={data.karenProfile.image.childImageSharp.fluid} />
          <FullWidthBar>
            <PartnersBar />
          </FullWidthBar>
          <CarouselAboutUs />
        </LayoutInner>
        <ContactBar />
      </Layout>
    </>
  )
}

export default AboutPage
