import React from "react"
import Img from "gatsby-image"

import "./KarenProfile.css"

const KarenProfile = ({ heading, text, image }) => {
  return (
    <div className="KarenProfileContainer">
      <div className="ProfileTextContainer">
        <h3>{heading}</h3>
        <p>{text}</p>
      </div>
      <div className="ProfileImageContainer">
        <Img fluid={image} />
      </div>
    </div>
  )
}

export default KarenProfile
